<template>
    <div>
        <AdminTop
            heading="Användare"
            add-new-url="/admin/users/new"
        />

        <BaseTable :show-delete="true">
            <TableHeader>
                <TableHeaderCell>
                    Användare
                </TableHeaderCell>

                <TableHeaderCell>
                    Klass
                </TableHeaderCell>

                <TableHeaderCell>
                    Roll
                </TableHeaderCell>
            </TableHeader>

            <TableBody>
                <TableRow
                    v-for="(user, index) in users"
                    :key="user.id"
                    :to="`/admin/users/${user.id}`"
                    @delete="removeUser(user.id, index)"
                >
                    <TableCell>
                        <div class="flex items-center">
                            <Avatar
                                class="mr-6"
                                :name="user.name"
                                :image="user.image"
                            />

                            <p class="text-gray-900 whitespace-no-wrap">
                                {{ user.name }}
                            </p>
                        </div>
                    </TableCell>

                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap">
                            {{ user.school_class ? user.school_class.name : null }}
                        </p>
                    </TableCell>

                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap capitalize">
                            {{ user.roles.map(role => role.name).join(', ') }}
                        </p>
                    </TableCell>
                </TableRow>
            </TableBody>
        </BaseTable>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseTable from '@/components/table/BaseTable';
import TableHeader from '@/components/table/TableHeader';
import TableHeaderCell from '@/components/table/TableHeaderCell';
import TableBody from '@/components/table/TableBody';
import TableRow from '@/components/table/TableRow';
import TableCell from '@/components/table/TableCell';
import Avatar from '@/components/ui/Avatar';
import UserService from '@/services/admin/UserService';
import { confirmRemove } from '@/utils/utils.js';

export default {
    components: {
        AdminTop,
        BaseTable,
        TableHeader,
        TableHeaderCell,
        TableBody,
        TableRow,
        TableCell,
        Avatar
    },

    data: () => ({
        users: []
    }),

    created () {
        this.getUsers();
    },

    methods: {
        async getUsers () {
            const res = await UserService.all();

            this.users = res.data;
        },

        async removeUser (id, index) {
            if (!confirmRemove()) {
                return;
            }

            const res = await UserService.remove(id);

            if (res && res.status === 200) {
                const users = [...this.users];

                users.splice(index, 1);

                this.users = users;
            }
        }
    }
};
</script>

<style lang="postcss" scoped>
.avatar {
    width: 4.5rem;
    height: 4.5rem;
}

.avatar >>> .initials {
    font-size: 1.4rem;
}
</style>
